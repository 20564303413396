import dataProcessing from 'store/modules/dataProcessing';
import downloads from 'store/modules/downloads';
import locationsAndAssets from 'store/modules/locationsAndAssets';
import reports from 'store/modules/reports';
import smartview from 'store/modules/smartview';
import teams from 'store/modules/teams';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const general = {
    namespaced: false,
    state: {
        darkTheme: false
    },
    mutations: {
        setDarkTheme(state, value) {
            state.darkTheme = value;
        }
    },
    getters: {
        darkTheme(state) {
            return state.darkTheme;
        }
    }
};

export const store = new Vuex.Store({
    modules: {
        smartview,
        reports,
        general,
        locationsAndAssets,
        teams,
        downloads,
        dataProcessing
    }
});

export const SmartViewEventBus = new Vue();

if (module.hot) {
    // accept actions and mutations as hot modules
    module.hot.accept(
        [
            './modules/smartview',
            './modules/reports',
            './modules/locationsAndAssets',
            './modules/teams',
            './modules/downloads',
            './modules/dataProcessing'
        ],
        () => {
            // require the updated modules
            // TODO why is require needed here?
            // have to add .default here due to babel 6 module output
            // eslint-disable-next-line global-require
            const newModuleSmartview = require('./modules/smartview.js')
                .default;
            // eslint-disable-next-line global-require
            const newModuleReports = require('./modules/reports.js').default;
            // eslint-disable-next-line global-require
            const newModuleLocationsAndAssets = require('./modules/locationsAndAssets.js')
                .default;
            // eslint-disable-next-line global-require
            const newTeams = require('./modules/teams.js').default;
            // eslint-disable-next-line global-require
            const newDownloads = require('./modules/downloads.js').default;
            // eslint-disable-next-line global-require
            const newDataProcessing = require('./modules/dataProcessing')
                .default;
            // swap in the new modules and mutations
            store.hotUpdate({
                modules: {
                    smartview: newModuleSmartview,
                    reports: newModuleReports,
                    locationsAndAssets: newModuleLocationsAndAssets,
                    teams: newTeams,
                    downloads: newDownloads,
                    dataProcessing: newDataProcessing
                }
            });
        }
    );
}
